
export class AddRequest{
  id :number;
  ruleGeneral: string;
  ruleSubject: string;
  ruleJustification: string;
  ruleFile:FileList;
  expires: Date;
  requestor: string;
  requestorGroup: string;

  constructor(
    id:number,
    ruleGeneral: string,
    ruleSubject: string,
    ruleJustification: string,
    ruleFile:FileList,
    expires: Date,
    requestor: string,
    requestorGroup: string
  ) {
    this.id = id;
    this.ruleGeneral = ruleGeneral;
    this.ruleSubject = ruleSubject;
    this.ruleJustification = ruleJustification;
    this.ruleFile = ruleFile
    this.expires = expires;
    this.requestor = requestor;
    this.requestorGroup = requestorGroup;
  }
}



