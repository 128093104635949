import { Injectable } from '@angular/core';
import {AddRequest} from "../model/addRequest";
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Traffic} from "../model/traffic";

@Injectable({
  providedIn: 'root'
})
export class AddRequestRepoService {


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  private apiUrl = ' http://localhost:8080'
  constructor(private http: HttpClient) { }


  sendRequest(addRequest: AddRequest): Observable<any> {
    const formData = new FormData();
    formData.append('id', addRequest.id.toString());
    formData.append('ruleGeneral', addRequest.ruleGeneral);
    formData.append('ruleSubject', addRequest.ruleSubject);
    formData.append('ruleJustification', addRequest.ruleJustification);
    formData.append('expires', addRequest.expires.toISOString());
    formData.append('requestor', addRequest.requestor);
    formData.append('requestorGroup', addRequest.requestorGroup);
    if (addRequest.ruleFile) {
      for (let i = 0; i < addRequest.ruleFile.length; i++) {
        formData.append('ruleFile', addRequest.ruleFile[i], addRequest.ruleFile[i].name);
      }
    }
    return this.http.post(this.apiUrl+'api/add-request', formData, this.httpOptions);
  }


  addTraffic(addTraffic: Traffic): Observable<any> {
    const formData = new FormData();
    formData.append('id', addTraffic.id.toString());
    formData.append('source', addTraffic.source);
    formData.append('destination', addTraffic.destination);
    formData.append('service', addTraffic.service);
    formData.append('action', addTraffic.action);
    formData.append('requestedSourceGroupName', addTraffic.requestedSourceGroupName);
    formData.append('requestedDestinationGroupName', addTraffic.requestedDestinationGroupName);
    formData.append('requestedServiceGroupName', addTraffic.requestedServiceGroupName);
    formData.append('accessLists', addTraffic.accessLists);

    return this.http.post(this.apiUrl+'api/add-traffic', formData, this.httpOptions);
  }
}
