import { Injectable } from '@angular/core';
import {AddRequestRepoService} from "../repo/add-request.repo.service";
import {AddRequest} from "../model/addRequest";
import {first} from "rxjs";
import {Traffic} from "../model/traffic";

@Injectable({
  providedIn: 'root'
})
export class AddRequestStoreService {

  constructor(private repo: AddRequestRepoService) { }


  sendRequest(request: AddRequest){
    this.repo.sendRequest(request).pipe(first()).subscribe(request =>{
    });
  }

  addTraffic(traffic: Traffic){
    this.repo.addTraffic(traffic).pipe(first()).subscribe(traffic=>{
    });
  }


}
