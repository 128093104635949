<mat-card>
    <mat-card-title>Create a New Change Request</mat-card-title>
    <mat-card-header>BWI Rule Add Request</mat-card-header>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              General
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content>
            <form [formGroup]="ruleCreateForm">
              <div class="form-header">
                <div class="form-buttons">
                  <button mat-raised-button color="primary" type="submit" (click)="onSaveDraft()">Save Draft</button>
                  <button mat-raised-button color="primary" type="button" (click)="prevStep()">Back</button>
                  <button mat-raised-button color="primary" type="button" (click)="nextStep()">Next</button>
                </div>
              </div>
          <div>
            <mat-form-field class="input-field" appearance="outline">
              <mat-label>Subject</mat-label>
              <input matInput placeholder="Enter subject" formControlName="ruleSubject">
              <mat-error></mat-error>
            </mat-form-field>
          </div>

          <mat-form-field class="input-field" appearance="outline">
            <mat-label>Change request justification</mat-label>
            <textarea matInput placeholder="Enter justification" formControlName="ruleJustification"></textarea>
            <mat-error></mat-error>
          </mat-form-field>

          <div class="form-group" appearance="outline">
            <label class="attachments-label">Attachments</label>
            <input type="file" multiple #fileInput class="test" (change)="handleFileInput(fileInput.files)">
            <button mat-raised-button color="primary" (click)="fileInput.click()">
              <mat-icon>attach_file</mat-icon>
              Add files...
            </button>
            <input matInput [value]="getFileName(fileInput.files)" readonly placeholder="">
          </div>

          <mat-form-field class="input-field" appearance="outline">
            <mat-label>Expires</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="expires">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error></mat-error>
          </mat-form-field>

          <div class="form-row">
            <mat-form-field class="input-field" appearance="outline">
              <mat-label>Requestor</mat-label>
              <input matInput required placeholder="Enter requestor" formControlName="requestor">
              <mat-error></mat-error>
            </mat-form-field>

            <mat-form-field class="input-field" appearance="outline">
              <mat-label>Requestor Group</mat-label>
              <mat-select formControlName="requestorGroup">
                <mat-option value="group1">Group 1</mat-option>
                <mat-option value="group2">Group 2</mat-option>
              </mat-select>
              <mat-error></mat-error>
            </mat-form-field>
          </div>
      </form>
    </mat-card-content>
        </mat-expansion-panel>


  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Traffic
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="CreateTrafficForm">
      <mat-card-content>

        <div class="form-header">
          <button mat-raised-button color="primary" type="button">Set traffic values</button>
          <div class="form-buttons">
            <button mat-raised-button color="primary" type="button">Import traffic from CSV</button>
          </div>
        </div>

        <div class="card-content" *ngFor="let card of cards; let i = index">
          <!-- Card Section -->
          <div class="numbered-card">
            <div class="card-header">
              <div class="card-number">{{ i + 1 }}</div>
            </div>
            <div class="form-row">

              <mat-form-field class="input-field" appearance="outline">
                <mat-label>Source</mat-label>
                <mat-chip-grid #chipGrid aria-label="Enter ips">
                  @for (ip of ips(); track ip) {
                  <mat-chip-row
                    (removed)="remove(ip)"
                    [editable]="true"
                    (edited)="edit(ip, $event)"
                    [aria-description]="'press enter to edit ' + ip.ip"
                  >
                  {{ip.ip}}
                  <button matChipRemove [attr.aria-label]="'remove ' + ip.ip">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                  }
                  <input
                    placeholder="New ip..."
                    [matChipInputFor]="chipGrid"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)"
                  />
                </mat-chip-grid>
              </mat-form-field>

              <mat-form-field class="input-field" appearance="outline">
                <mat-label>Destination</mat-label>
                <mat-chip-grid #chipGrid2 aria-label="Enter ips">
                  @for (ip of destIps(); track ip) {
                  <mat-chip-row
                    (removed)="removedest(ip)"
                    [editable]="true"
                    (edited)="edit(ip, $event)"
                    [aria-description]="'press enter to edit ' + ip.ip"
                  >
                  {{ip.ip}}
                  <button matChipRemove [attr.aria-label]="'remove ' + ip.ip">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                  }
                  <input
                    placeholder="New ip..."
                    [matChipInputFor]="chipGrid2"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="adddest($event)"
                  />
                </mat-chip-grid>
              </mat-form-field>


              <mat-form-field class="input-field" appearance="outline">
                <mat-label>Service
                  <mat-icon matTooltip="e.g. tcp/80,
                                  udp/*,
                                  icmp/21-22,
            *" class="info-icon">help_fill</mat-icon>
                </mat-label>
                <mat-select formControlName="service">
                  <mat-option value="service1">Service 1</mat-option>
                </mat-select>
                <mat-error *ngIf="ruleCreateForm.get('service')?.hasError('required')">
                  Service is required
                </mat-error>
              </mat-form-field>

              <mat-form-field class="input-field" appearance="outline">
                <mat-label>Action</mat-label>
                <mat-select formControlName="action">
                  <mat-option value="Allow">Allow</mat-option>
                  <mat-option value="Drop">Drop</mat-option>
                </mat-select>
                <mat-error *ngIf="ruleCreateForm.get('action')?.hasError('required')">
                  Action is required
                </mat-error>
              </mat-form-field>
              <mat-icon (click)="copyCard(i)">content_copy</mat-icon>
                <mat-icon *ngIf="cards.length >= 2" (click)="deleteDuplicateCard(i)">delete</mat-icon>
            </div>



            <div class="form-row">
              <mat-form-field class="input-field" appearance="outline">
                <mat-label>Requested Source Group Name
                  <mat-icon matTooltip="" class="info-icon">help_fill</mat-icon>
                </mat-label>
                <input matInput formControlName="requestedSourceGroupName">
                <mat-error *ngIf="ruleCreateForm.get('requestedSourceGroupName')?.hasError('required')">
                  Requested Source Group Name is required
                </mat-error>
              </mat-form-field>

              <mat-form-field class="input-field" appearance="outline">
                <mat-label>Requested Destination Group Name
                  <mat-icon matTooltip="" class="info-icon">help_fill</mat-icon>
                </mat-label>
                <input matInput formControlName="requestedDestinationGroupName">
                <mat-error *ngIf="ruleCreateForm.get('requestedDestinationGroupName')?.hasError('required')">
                  Requested Destination Group Name is required
                </mat-error>
              </mat-form-field>

              <mat-form-field class="input-field" appearance="outline">
                <mat-label>Requested Service Group Name
                  <mat-icon matTooltip="" class="info-icon">help_fill</mat-icon>
                </mat-label>
                <input matInput formControlName="requestedServiceGroupName">
                <mat-error *ngIf="ruleCreateForm.get('requestedServiceGroupName')?.hasError('required')">
                  Requested Service Group Name is required
                </mat-error>
              </mat-form-field>
            </div>

            <mat-form-field class="input-field" appearance="outline">
              <mat-label>Access Lists
                <mat-icon matTooltip="" class="info-icon">help_fill</mat-icon>
              </mat-label>
              <input matInput formControlName="accessLists">
              <mat-error *ngIf="ruleCreateForm.get('accessLists')?.hasError('required')">
                Access Lists are required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>

      <div class="button-more">
        <button mat-raised-button color="primary" type="button" (click)="duplicateCard()">
          <mat-icon>add</mat-icon>
          Add More traffic
        </button>
      </div>




      <div class="form-header">
        <button mat-raised-button color="primary" type="button" (click)="prevStep()" class="back-button">Back</button>
        <div class="form-buttons">
          <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">Save Draft</button>
          <button mat-raised-button color="primary" type="button">Next</button>
        </div>
      </div>
    </form>


  </mat-expansion-panel>

  <mat-expansion-panel #expansionPanel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        More
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-label>External change request id</mat-label>
    <mat-form-field appearance="outline" class="input-field">
      <input matInput placeholder="">
    </mat-form-field>
  </mat-expansion-panel>
</mat-card>


