import {Component, inject, OnInit, signal} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../../alert.service";
import {AddRequest} from "../../model/addRequest";
import {AddRequestStoreService} from "../../store/add-request.store.service";
import {Traffic} from "../../model/traffic";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipEditedEvent, MatChipInputEvent} from "@angular/material/chips";
import {LiveAnnouncer} from "@angular/cdk/a11y";


class ipControl {
  ip! : string
}

@Component({
  selector: 'app-regel-erstellen',
  templateUrl: './regel-erstellen.component.html',
  styleUrl: './regel-erstellen.component.scss'
})
export class RegelErstellenComponent implements OnInit {
  currentStep = 1;
  ruleCreateForm: FormGroup;
  CreateTrafficForm: FormGroup;
  cards: FormGroup[] = [];
  readonly addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  readonly ips = signal<ipControl[]>([]);
  readonly destIps = signal<ipControl[]>([]);

  readonly announcer = inject(LiveAnnouncer);
  constructor(private fb: FormBuilder, private alertService: AlertService, private store: AddRequestStoreService) {
    this.ruleCreateForm = this.fb.group({
      ruleGeneral: ['', Validators.required],
      ruleSubject: ['', Validators.required],
      ruleJustification: ['', Validators.required],
      ruleFile: [null],
      expires: [null],
      requestor: ['Tim.Backeler.ext@bwi.de', Validators.required],
      requestorGroup: ['', Validators.required]
    });

    this.CreateTrafficForm = this.fb.group({
      source: ['', Validators.required],
      destination: ['', Validators.required],
      service: ['', Validators.required],
      action: ['Allow', Validators.required],
      requestedSourceGroupName: ['', Validators.required],
      requestedDestinationGroupName: ['', Validators.required],
      requestedServiceGroupName: ['', Validators.required],
      accessLists: ['', Validators.required]
    });

    // Initialize with one card
    this.cards.push(this.createTrafficFormGroup());
  }

  ngOnInit() {
    this.currentStep = 1;
  }

  createTrafficFormGroup(): FormGroup {
    return this.fb.group({
      source: ['', Validators.required],
      destination: ['', Validators.required],
      service: ['', Validators.required],
      action: ['Allow', Validators.required],
      requestedSourceGroupName: ['', Validators.required],
      requestedDestinationGroupName: ['', Validators.required],
      requestedServiceGroupName: ['', Validators.required],
      accessLists: ['', Validators.required]
    });
  }

  duplicateCard() {
    this.cards.push(this.createTrafficFormGroup());
  }

  deleteDuplicateCard(index: number) {
    if (this.cards.length > 1) {
      this.cards.splice(index, 1);
    }
  }

  copyCard(index: number) {
    const copiedCard = this.createTrafficFormGroup();
    this.cards.push(copiedCard);
  }

  onSaveDraft() {
    if (this.ruleCreateForm.valid) {
      const formValues = this.ruleCreateForm.value;
      const addRequest = new AddRequest(
        0,
        formValues.ruleGeneral,
        formValues.ruleSubject,
        formValues.ruleJustification,
        formValues.ruleFile,
        formValues.expires,
        formValues.requestor,
        formValues.requestorGroup
      );
      this.store.sendRequest(addRequest);
      this.alertService.showAlert('Daten erfolgreich gespeichert!', 'success');
    } else {
      console.error('Form is invalid');
      this.alertService.showAlert('Beim Speichern der Daten ist ein Fehler aufgetreten.', 'error');
    }
  }

  onSubmit() {
    if (this.CreateTrafficForm.valid) {
      this.cards.forEach((card, index) => {
        const formValues = card.value;
        const addTraffic = new Traffic(
          index,
          formValues.source,
          formValues.destination,
          formValues.service,
          formValues.action,
          formValues.requestedSourceGroupName,
          formValues.requestedDestinationGroupName,
          formValues.requestedServiceGroupName,
          formValues.accessLists
        );
        this.store.addTraffic(addTraffic);
      });
      this.alertService.showAlert('Daten erfolgreich gespeichert!', 'success');
    } else {
      console.error('Form is invalid');
      this.alertService.showAlert('Beim Speichern der Daten ist ein Fehler aufgetreten.', 'error');
    }
  }

  getFileName(files: FileList | null): string {
    if (!files || files.length === 0) {
      return '';
    }
    return files[0].name;
  }

  handleFileInput(files: FileList | null) {
    if (files) {
      this.ruleCreateForm.patchValue({
        ruleFile: files
      });
    }
  }

  nextStep() {
    if (this.currentStep === 1) {
      this.currentStep++;
    }
  }

  prevStep() {
    if (this.currentStep === 2) {
      this.currentStep--;
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.ips.update(ips => [...ips, {ip: value}]);
    }
    event.chipInput!.clear();
  }

  adddest(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.destIps.update(ips => [...ips, {ip: value}]);
    }
    event.chipInput!.clear();
  }

  remove(ip: ipControl): void {
    this.ips.update(ips => {
      const index = ips.indexOf(ip);
      if (index < 0) {
        return ips;
      }
      ips.splice(index, 1);
      this.announcer.announce(`Removed ${ip.ip}`);
      return [...ips];
    });
  }

  removedest(ip: ipControl): void {
    this.destIps.update(ips => {
      const index = ips.indexOf(ip);
      if (index < 0) {
        return ips;
      }
      ips.splice(index, 1);
      this.announcer.announce(`Removed ${ip.ip}`);
      return [...ips];
    });
  }

  edit(ip: ipControl, event: MatChipEditedEvent) {
    const value = event.value.trim();
    if (!value) {
      this.remove(ip);
      return;
    }

    this.ips.update(ips => {
      const index = ips.indexOf(ip);
      if (index >= 0) {
        ips[index].ip = value;
        return [...ips];
      }
      return ips;
    });
  }

  editdest(ip: ipControl, event: MatChipEditedEvent) {
    const value = event.value.trim();
    if (!value) {
      this.removedest(ip);
      return;
    }

    this.destIps.update(ips => {
      const index = ips.indexOf(ip);
      if (index >= 0) {
        ips[index].ip = value;
        return [...ips];
      }
      return ips;
    });
  }




}
