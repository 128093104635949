export class Traffic{
  id: number;
  source: string;
  destination:string;
  service: string;
  action: string;
  requestedSourceGroupName: string;
  requestedDestinationGroupName:string;
  requestedServiceGroupName: string;
  accessLists: string;

  constructor(
    id :number,
    source: string,
    destination:string,
    service: string,
    action: string,
    requestedSourceGroupName: string,
    requestedDestinationGroupName:string,
    requestedServiceGroupName: string,
    accessLists: string
  ) {
    this.id = id;
    this.source = source;
    this.destination = destination;
    this.service = service;
    this.action = action;
    this.requestedSourceGroupName = requestedSourceGroupName;
    this.requestedDestinationGroupName = requestedDestinationGroupName;
    this.requestedServiceGroupName = requestedServiceGroupName;
    this.accessLists = accessLists;
  }

}
