import { NgModule } from '@angular/core';
import { RegelErstellenComponent } from './regel-erstellen.component';
import { RegelerstellenRoutingModule } from './regelerstellen-routing.module';
import { SharedModule } from '../../Shared/shared.module';
import {FormsModule} from "@angular/forms";
import {MatChip, MatChipListbox, MatChipsModule} from "@angular/material/chips";

@NgModule({
  declarations: [
    RegelErstellenComponent,
  ],
    imports: [
        RegelerstellenRoutingModule,
        SharedModule,
        FormsModule,
        MatChip,
        MatChipsModule
    ]
})
export class RegelErstellenModule { }
